import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.common.white,
    },
    title: {
      color: theme.palette.labraText.primary,
      fontSize: '24px',
      fontStyle: 'normal',
      fontFamily:'Poppins',
      fontWeight: 400,
      lineHeight: '32px',
      padding:theme.spacing(2, 5, 2, 5)
    },
    upperContainer: {
      width: '100%',
    },
    image : {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      height: theme.spacing(4),
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(5),
    },
    logo: {
      width: theme.spacing(4.875),
      height: theme.spacing(2.875),
      
    },
  })
)
