import React, { lazy, Suspense, useEffect, useRef } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Product } from '../../modules/partner/action'
import { UserProfile } from '../../pages/components/UserProfile/UserProfile'
import { ProductRoutingMap } from '../../utils/productRoutingMap'
import { ProductSelectorWrapper } from '../ProductSelector/ProductSelectorWrapper'
import { PageNotFound } from '../PageNotFound/PageNotFound'
import { UserAndPermission } from '../../../oppsync/pages/components/UserAndPermission/UserAndPermission'
import { isEmpty, uniq } from 'lodash'
import RouteApp from '../../pages/components/RouteApp/RouteApp'
import { LayoutWrapper } from '../RoutingApp/RoutingApp'
import { usePartnerId } from '../../utils/Hooks/usePartnerData'
import { SnackBar } from '../SnackBar/SnackBar'
import { LabraHelpAndFeedback } from '../LabraHelpAndFeedback/LabraHelpAndFeedback'
import { SidePanelWrapperV3 } from '../SidePanelWrapperV3/SidePanelWrapperV3'
import { useAnyOneOnboardingCompeted } from '../SidePanelWrapperV3/useAnyOneOnboardingCompeted'
import OppsyncBlockPage from '../BlockPages/OppsyncBlockPage'
import FlyoutBlockPage from '../BlockPages/FlyoutBlockPage'
import { LoadingLogo } from '@labrav/react-components'

export type LandingPageWrapperProps = {
  activeProducts: Product[] | string[]
}

const ProductRoutes: Record<
  Product,
  React.LazyExoticComponent<() => JSX.Element>
> = {
  oppsync: lazy(
    () => import('../../../oppsync/pages/OppSyncApplicationRoutings')
  ),
  cloudfaas: lazy(
    () => import('../../pages/components/LandingPage/CloudfaasTempPage')
  ),
  flyout: lazy(
    () => import('../../pages/components/LandingPage/CmcmLandingPage')
  ),
}

const SettingsComponent = lazy(() => import('../Settings'))

export const LandingPageWrapperV3: React.FC<LandingPageWrapperProps> = ({
  activeProducts,
}) => {
  const partnerId = usePartnerId()
  const prevPartnerIdRef = useRef<string | undefined>(partnerId)
  const pathName = window.location.pathname
  const isNotInProductSelection = pathName !== '/product-selection'

  const { firstProduct } = useAnyOneOnboardingCompeted(activeProducts)

  const Settings = (
    <LayoutWrapper
      title={'My settings'}
      component={SettingsComponent}
      paddingZero
    />
  )

  useEffect(() => {
    if (prevPartnerIdRef.current && partnerId !== prevPartnerIdRef.current) {
      window.location.reload()
    }
    prevPartnerIdRef.current = partnerId
  }, [partnerId])
  
  const isLoading = activeProducts?.length > 0 && isEmpty(firstProduct)

  if(isLoading){
    return <LoadingLogo />
  }

  return (
    <>
      <SidePanelWrapperV3
        isNotInProductSelection={isNotInProductSelection}
        activeProducts={activeProducts as Product[]}
      />
      <LabraHelpAndFeedback />
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route
            path="/user/profile"
            element={<RouteApp title="My profile" component={UserProfile} />}
          />
          <Route
            path="/users"
            element={
              <RouteApp
                title="User & Permission"
                component={UserAndPermission}
              />
            }
          />

          <Route path="/settings/*" element={Settings} />
          <Route
            path="/product-selection"
            element={<ProductSelectorWrapper />}
          />
          {(activeProducts.length > 0 && !isEmpty(firstProduct)) &&
            redirectToProduct(
              ProductRoutingMap,
              activeProducts as Product[],
              firstProduct
            )}
          <Route path="/" element={<Navigate to="/product-selection" />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
      <SnackBar />
    </>
  )
}

const redirectToProduct = (
  ProductRoutingMap: Record<Product, string>,
  activeProducts: Product[],
  firstProduct?: string
) => {
  return [
    ...activeProducts.map(product => {
      const Comp = ProductRoutes[product]
      return (
        <Route
          key={product + 'activeAndCurrentProduct'}
          path={`/${ProductRoutingMap[product]}/*`}
          element={<Comp />}
        />
      )
    }),
    <Route
      key={'redirect-to-co-sell-block-page'}
      path={'/oppsync/block-page'}
      element={
        <LayoutWrapper
          paddingZero={true}
          component={OppsyncBlockPage}
          isSidePanelOpened={false}
        />
      }
    />,
    <Route
      key={'redirect-to-marketplace-block-page'}
      path={'/flyout/block-page'}
      element={
        <LayoutWrapper
          paddingZero={true}
          component={FlyoutBlockPage}
          isSidePanelOpened={false}
        />
      }
    />,
    <Route
      key="redirect-to-product-or-product-selection"
      path="/"
      element={
        activeProducts.length > 0 ? (
          <Navigate to={`/${firstProduct}`} />
        ) : (
          <Navigate to={'/product-selection'} />
        )
      }
    />,
  ]
}
