import { LandingPageWrapper } from '../../../components/LandingPageWrapper/LandingPageWrapper'
import { useActiveProducts } from '../../../utils/Hooks/activeProducts'
import { CurrentProductWrapper } from '../../../components/CurrentProductWrapper/CurrentProductWrapper'
import { usePartnerUser } from '../../../utils/Hooks/partner'
import { useTimeZone } from '../../../utils/Hooks/time'
import { useOnboarding } from '../../../utils/Hooks/onboarding'
import { LoadingLogo } from '@labrav/react-components'
import { useGetUsers } from '../../../utils/Hooks/useGetUsers'
import { useSellerInfo } from '../../../utils/Hooks/useSellerInfo'
import { useOppSyncOnboardingStatus } from '../../../../oppsync/utils/hooks/useOppSyncOnboardingStatus'
import { useFlagValue } from '@labrav/flags'
import { LandingPageWrapperV3 } from '../../../components/LandingPageWrapper/LandingPageWrapperV3'

export const LandingPageRouting = () => {
  const activeProducts = useActiveProducts()
  const { loading: partnerLoading } = usePartnerUser()
  const { loading: timezoneLoading } = useTimeZone()
  const { loading: onboardingLoading } = useOnboarding()
  const { loading: getUsersLoading } = useGetUsers()
  const { loading: sellerInfoLoading } = useSellerInfo()
  const { loading: isFlagCosellLoading, value: cosellEnabled } =
    useFlagValue('cosell')

    const { value: adminUiFeatureSelectionEnabled, loading: adminUiFeatureSelectionLoading } = useFlagValue(
      'adminUiFeatureSelection'
    )
  const isCosellEnabled = !isFlagCosellLoading && cosellEnabled

  const { isNewPartner } = useOppSyncOnboardingStatus(isCosellEnabled)

  if (
    partnerLoading ||
    timezoneLoading ||
    (!isNewPartner && onboardingLoading) ||
    getUsersLoading || adminUiFeatureSelectionLoading
  ) {
    return <LoadingLogo />
  }

  const LandingPageComp = adminUiFeatureSelectionEnabled ? LandingPageWrapperV3 : LandingPageWrapper

  return (
    <CurrentProductWrapper activeProducts={activeProducts}>
      <LandingPageComp activeProducts={activeProducts} />
    </CurrentProductWrapper>
  )
}

export default LandingPageRouting
