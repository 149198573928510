import { AnyAction, Dispatch } from 'redux'
import { AppState } from '../../../store/reducer'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { errorLogger } from '../../../common/utils/errorLogger'
import { postSellerCreation } from '../../api/markeplace'
import { snakeize } from 'casing'
import {
  CloudProvider,
  PartnershipTypeValue,
  Product,
} from '../../../common/modules/partner/action'
import { AppDispatch, RootState } from '../../../store'
import { formattedActiveProducts } from '../../../common/utils/formattedProducts'
import { FlyoutOnboardingPartnershipType } from '../flyOutOnboarding/reducer'

export interface SellerCreationMetadata {
  metadata: {
    cloudProvider: CloudProvider
  }
}

export const sellerCreation =
  ({
    partnerId,
    awsPartnershipType,
    cloudProvider,
    activeProducts,
    product,
    updatePartnerData,
  }: {
    partnerId: string
    awsPartnershipType?: PartnershipTypeValue[]
    cloudProvider: CloudProvider
    activeProducts?: Product[] | string[]
    product?: Product
    updatePartnerData?: (
      activeProducts: Product[] | string[],
      product: Product,
      cloudProvider: CloudProvider
    ) => Promise<void>
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const partnershipType =
        awsPartnershipType && awsPartnershipType.includes('CONSULTING_PARTNER')
          ? FlyoutOnboardingPartnershipType.CP
          : FlyoutOnboardingPartnershipType.ISV
      const payload = {
        partnerId,
        partnershipType,
        metadata: {
          cloudProvider: cloudProvider.toUpperCase(),
        },
      }
      await postSellerCreation(snakeize(payload))
      if (updatePartnerData && activeProducts && product) {
        await updatePartnerData(activeProducts, product, cloudProvider)
      }
    } catch (err) {
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
