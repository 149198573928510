import { DateTime } from 'luxon'
import { useDispatch, useSelector } from '../../../store'
import { isEmpty } from 'lodash'
import {
  CRMTypes,
  labraV2OnboardingReleaseDate,
} from '../../../common/utils/constants'
import {
  customerFormData,
  usePartnerId,
  useUserType,
} from '../../../common/utils/Hooks/usePartnerData'
import { useEffect } from 'react'
import { fetchAccounts } from '../../../common/modules/cmcm/accounts/actions'
import { CloudType } from '../../../common/modules/types'
import { gethasAWSCustomCatalog } from '../../../common/modules/partner/action'
import { CmcmAccountState } from '../../../common/modules/cmcm/accounts/reducer'
import { AWSAccountDetails } from '../../modules/productAccount/reducer'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { isLoading as Loading } from '../../../common/utils/loadingState'
import { getAccountRegistrationData } from '../../modules/productAccount/action'
import { getCRMFormData } from '../../../common/modules/onboarding/crmAuth/action'

export type OppSyncOnboardingStatus = {
  hasCompanyInfo: boolean
  hasConnectedCrmAtLeastOnce: boolean
}

export const useOppSyncOnboardingStatus = (
  isCosellEnabled: boolean = false
) => {
  const { crmType } = customerFormData()
  const isCrmLabra = crmType === 'labra'
  const partnerId = usePartnerId()
  const dispatch = useDispatch()
  const partnerType = useUserType()

  const crmId = useSelector<string | undefined>(
    state => state?.PartnerData.user.partnerData?.crmId
  )

  const { deletedAt, crmIdFromCRMAuth, crmAuth, isLoading, isCrmConnected } =
    useSelector<any>(state => {
      const isCrmConnected =
        !isEmpty(state.CRMAuth.crmFormData) &&
        !isEmpty(crmId) &&
        (state.CRMAuth.crmFormData?.creds || []).length > 0 &&
        isEmpty(state.CRMAuth.crmFormData?.deleted_at)
      return {
        deletedAt: state.CRMAuth.crmFormData?.deleted_at || '',
        crmIdFromCRMAuth: state.CRMAuth.crmFormData?.crm_id || '',
        crmType: state.CRMAuth.crmFormData?.crm_type as CRMTypes,
        crmAuth: state.CRMAuth.crmFormData
          ? state.CRMAuth.crmFormData
          : undefined,
        isCrmConnected,
        isLoading: Loading(state.loading)(LoadingTypes.CRM_AUTH),
      }
    })

  const accountRegistrationData = useSelector<{
    accounts: CmcmAccountState
  }>(state => {
    return {
      accounts: state.accounts.oppsync,
    }
  })

  const AWSData = useSelector<AWSAccountDetails>(
    state => state.accountForm.oppsync.accountDetails.AWS
  )

  const isLoadingAccounts = useSelector<boolean>(state =>
    Loading(state.loading)(LoadingTypes.CMCM_FETCHING_ACCOUNTS)
  )

  const isLoadingTemplates = useSelector<boolean>(state =>
    Loading(state.loading)(LoadingTypes.TEMPLATES)
  )

  const isTemplateEmpty = isEmpty(AWSData.roles.rows[0])

  const hasAWSCustomCatalog = useSelector(
    state => state.PartnerData[partnerType].partnerData?.hasAWSCustomCatalog
  )

  const hasCompanyInfo = useSelector<boolean>(state => {
    const partnerData = state.PartnerData.user.partnerData
    return (
      !isEmpty(partnerData?.companyName) &&
      !isEmpty(partnerData?.websiteUrl) &&
      !isEmpty(partnerData?.industry) &&
      !isEmpty(partnerData?.partnershipType)
    )
  })

  const hasConnectedCrmAtLeastOnce = useSelector<boolean>(state => {
    return (
      !isEmpty(state.CRMAuth.crmFormData) &&
      !isEmpty(state.PartnerData.user?.partnerData?.crmId) &&
      (state.CRMAuth.crmFormData?.creds || []).length > 0 &&
      isEmpty(state.CRMAuth.crmFormData?.deleted_at)
    )
  })

  const hasArnRoleSetup = useSelector<boolean>(state => {
    const hasArnRoleSetup =
      (state.accounts.oppsync || [])[0]?.accountDetails?.roles?.rows?.length >
        1 ||
      !isEmpty(
        state.accountForm.oppsync?.accountDetails?.AWS?.roles?.rows[0]?.inputsFields.find(
          i => i.keyName === 'iamRoleArn'
        )?.value
      )
    return hasArnRoleSetup
  })

  const hasPolicySetup = useSelector<boolean>(state => {
    const partnerData = state.PartnerData.user.partnerData
    return (
      !isEmpty(partnerData?.iamPolicyProduction) &&
      !isEmpty(partnerData?.iamPolicyStaging)
    )
  })

  const hasSolutionsAndServices = useSelector<boolean>(
    state => state.PartnerData.user.partnerData?.hasAWSCustomCatalog ?? false
  )

  const loading = useSelector<boolean>(state => {
    const partnerLoading = !state.PartnerData.user.partnerData
    const arnLoading = state.accounts.oppsync === null
    const hasAWSCustomCatalog =
      state.PartnerData.user.partnerData?.hasAWSCustomCatalog
    const hasAWSCustomCatalogLoading =
      hasAWSCustomCatalog === null || hasAWSCustomCatalog === undefined

    return partnerLoading || arnLoading || hasAWSCustomCatalogLoading || isLoading
  })

  const isNewPartner = useSelector<boolean>(state => {
    const partnerCreatedAt = state.PartnerData.user.partnerData?.createdAt
    if (!partnerCreatedAt) {
      return true
    }
    return (
      DateTime.fromISO(labraV2OnboardingReleaseDate) <
      DateTime.fromISO(partnerCreatedAt)
    )
  })

  const onboardingComplete =
    (hasCompanyInfo &&
      (isCrmLabra || hasConnectedCrmAtLeastOnce) &&
      hasArnRoleSetup &&
      hasPolicySetup &&
      hasSolutionsAndServices) ||
    !isNewPartner

  useEffect(() => {
    if (partnerId) {
      dispatch(fetchAccounts('', CloudType.AWS, 'oppsync'))
    }
  }, [partnerId])

  useEffect(() => {
    if (
      partnerId &&
      (hasAWSCustomCatalog === null || hasAWSCustomCatalog === undefined)
    ) {
      dispatch(gethasAWSCustomCatalog(partnerId, partnerType))
    }
  }, [partnerId])

  useEffect(() => {
    if (
      partnerId &&
      isTemplateEmpty &&
      accountRegistrationData.accounts &&
      !isLoadingAccounts &&
      !isLoadingTemplates
    ) {
      dispatch(
        getAccountRegistrationData(
          CloudType.AWS,
          '',
          'oppsync',
          partnerId,
          'oppsync',
          partnerType,
          accountRegistrationData.accounts[0]
        )
      )
    }
  }, [partnerId, isTemplateEmpty, isLoadingAccounts, isLoadingTemplates])

  useEffect(() => {
    if (crmId && isEmpty(crmAuth) && !isEmpty(crmId)) {
      dispatch(getCRMFormData(crmId))
    }
  }, [crmIdFromCRMAuth, crmAuth, crmId, deletedAt])

  return {
    loading,
    isNewPartner,
    onboardingComplete,
    hasCompanyInfo,
    hasConnectedCrmAtLeastOnce,
    hasArnRoleSetup,
    hasPolicySetup,
    hasSolutionsAndServices,
  }
}
