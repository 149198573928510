import { snakeize } from 'casing'
import { ProductType } from '../../../admin/modules/NewCustomers/reducer'

export type TrackerData = {
  isSidePanelTracking?: boolean
  product?: ProductType
  section: string
  component: string
  action: string
  [key: string]: unknown
}

export class Tracker {
  private segmentClient?: SegmentAnalytics.AnalyticsJS

  constructor(segmentClient?: SegmentAnalytics.AnalyticsJS) {
    this.segmentClient = segmentClient
  }

  public identify = (userId: string, context: Record<string, unknown>) => {
    this.segmentClient?.identify(userId, context)
  }

  public group = (groupName: string) => {
    this.segmentClient?.group(groupName)
  }

  /**
   * Track an analytics event following the naming convention:
   * product.page_location.ui_component.action_past_tense
   * e.g. oppsync.aws_cloud_settings.generate_iam_policies_button.clicked
   * more info: https://alexzran.medium.com/naming-convention-how-to-name-tracking-events-605792071591
   *
   * @param data event data/properties to track
   */
  public track = (data: TrackerData) => {
    const eventName = this.getEventName(data)

    if (data.isSidePanelTracking) {
      delete data.product
      delete data.isSidePanelTracking
    }

    this.segmentClient?.track(eventName, data)
  }

  private getEventName = (data: TrackerData) => {
    const { isSidePanelTracking, product, section, component, action } = data
    const eventParts = isSidePanelTracking
      ? [section, component, action]
      : [product, section, component, action]

    return eventParts
      .filter(Boolean)
      .map(str => (str ? str.toLowerCase() : ''))
      .map(snakeize)
      .join('.')
  }

  public page = () => {
    this.segmentClient?.page()
  }
}
