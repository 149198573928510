import { Customer } from '../../../admin/modules/NewCustomers/reducer'
import { ReferralDetailsProps } from '../../../oppsync/components/CoSell/ReferralDetails/ReferralDetails/ReferralDetails'
import {
  LeadStage,
  ReferralStage,
} from '../../../oppsync/components/CoSell/types'
import { CosellReferralType } from '../../../oppsync/modules/CoSell/actions'
import { useSelector } from '../../../store'
import { PartnerType, PartnershipTypeValue } from '../../modules/partner/action'
import { ProductType } from '../../modules/partner/reducer'
import { CRMTypes } from '../constants'
import { getReferralType } from '../helperFunctions'
import { isAdminRole } from '../isAdminRole'
import { UserRoleForTable } from '../roles'

const emptyCustomerForm = {
  companyName: '',
  quotationLink: '',
  internalNotes: '',
  crmType: null,
  numberOfSeats: 100,
  isInternal: false,
  includeLabraBot: false,
  users: [
    {
      email: '',
      role: 'PARTNER_OWNER' as UserRoleForTable,
    },
  ],
  subscribedProducts: {},
  partnershipType: [],
}

export const useUserType = () => {
  return useSelector<PartnerType>(state => {
    const isAdmin = isAdminRole(state.userProfile.userProfile)
    return isAdmin ? PartnerType.Admin : PartnerType.User
  })
}

export const usePartnerId = () => {
  const userType = useUserType()
  return useSelector<string | undefined>(
    state => state.PartnerData[userType]?.partnerData?.partnerId
  )
}

export const useCurrentProduct = (defaultProduct: ProductType) => {
  const userType = useUserType()
  return useSelector<ProductType>(
    state => state.PartnerData[userType]?.currentProduct || defaultProduct
  )
}

export const customerFormData = () => {
  const userType = useUserType()
  return useSelector<Customer>(state => {
    const partnerData = state?.PartnerData[userType]?.partnerData

    const newCustomerData = {
      companyName: partnerData?.companyName || emptyCustomerForm.companyName,
      quotationLink:
        partnerData?.quotationLink || emptyCustomerForm.quotationLink,
      internalNotes:
        partnerData?.internalNotes || emptyCustomerForm.internalNotes,
      crmType: partnerData?.crmType || emptyCustomerForm.crmType,
      numberOfSeats:
        partnerData?.numberOfSeats || emptyCustomerForm.numberOfSeats,
      isInternal: partnerData?.isInternal || emptyCustomerForm.isInternal,
      includeLabraBot:
        partnerData?.includeLabraBot || emptyCustomerForm.includeLabraBot,
      users: partnerData?.users || emptyCustomerForm.users,
      subscribedProducts:
        partnerData?.subscribedProducts || emptyCustomerForm.subscribedProducts,
      partnershipType:
        (partnerData?.partnershipType as PartnershipTypeValue[]) ||
        emptyCustomerForm.partnershipType,
    }
    return newCustomerData
  })
}
export const getCosellFormData = () => {
  return useSelector<ReferralDetailsProps | undefined>(state => {
    let cosellReferralType: CosellReferralType
    const typeFromOpportunity =
      state.referralFormCreation.referral?.referralMetadata?.referralType
    const typeFromLead =
      state.leadReferralForm.referral?.referralMetadata?.referralType
    if (typeFromOpportunity) {
      cosellReferralType = 'opportunity'
    } else if (typeFromLead) {
      cosellReferralType = 'lead'
    } else {
      // data is not ready so returning undefined
      return undefined
    }
    if (cosellReferralType === 'opportunity') {
      const referral = state.referralFormCreation.referral
      if (!referral || !referral.referralObjectTransformed) {
        // data is not ready so returning undefined
        return undefined
      }
      return {
        referralType: getReferralType(
          'opportunity',
          referral.referralObjectTransformed.referredBy || 'AWS Referral'
        ),
        referralId: referral.referralMetadata.referralId || '',
        referredBy: referral.referralObjectTransformed.referredBy,
        acceptanceStatus: referral.referralObjectTransformed.acceptanceStatus,
        targetCloseDate: referral.referralObjectTransformed?.targetCloseDate,
        acceptByDate: referral.referralObjectTransformed?.acceptByDate,
        lastSyncStatus: referral.referralMetadata?.lastSyncStatus,
        isDraft: referral.referralMetadata.referralStatus === 'draft',
        expectedMonthlyAwsRevenue:
          referral.referralObjectTransformed.expectedMonthlyAwsRevenue + '',
        cloudProviderAcceptanceStatus:
          referral.referralObjectTransformed.cloudProviderAcceptanceStatus,
        stage:
          referral.referralObjectTransformed.stage ||
          ('Prospect' as ReferralStage),
        crmUniqueIdentifier:
          referral.referralObjectTransformed.crmUniqueIdentifier,
        engagementScore: referral.referralObjectTransformed.engagementScore,
        createdAt: referral.referralObjectTransformed.createdDate,
        lastModifiedDate: referral.referralObjectTransformed.lastModifiedDate,
        lastUpdatedBy: referral.referralObjectTransformed.lastUpdatedBy,
      }
    } else {
      const referral = state.leadReferralForm.referral
      return {
        referralType: 'incoming-leads',
        referralId: referral?.referralObjectTransformed?.id || '',
        referredBy: referral?.referralObjectTransformed?.referredBy,
        acceptanceStatus: referral?.referralObjectTransformed?.acceptanceStatus,
        targetCloseDate: referral?.referralObjectTransformed?.labraAcceptByDate,
        lastSyncStatus: referral?.referralMetadata?.lastSyncStatus,
        campaignName: referral?.referralObjectTransformed?.campaignName,
        stage:
          referral?.referralObjectTransformed?.awsLeadStatus ||
          ('Prospect' as LeadStage),
        isDraft: referral?.referralMetadata.referralStatus === 'draft',
        crmUniqueIdentifier:
          referral?.referralObjectTransformed?.partnerCRMLeadId,
      }
    }
  })
}
