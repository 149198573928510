import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    maxHeight:'100%',
    maxWidth:'95%',
    margin: theme.spacing(5),
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    borderRadius:'4px',
    flexWrap: 'wrap',
  },
  lock: {
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(-1),
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    height: '100%',
    width: '80%',
    paddingLeft: theme.spacing(4),
  },
  header:{
      flexDirection: 'column',
      
  },
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '28px',
  },
  subTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    fontSize: '14px',
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: 400,
    width:'100%',
  },
  listItemText: {
    listStyleType: 'none',
    marginTop:'-5px',
    fontSize: '14px',
  },
  link:{
    height:'20px',
    marginTop:'32px'
  },
  button: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  smallImage: {
    width: '100%',
    maxHeight: '70px',
    objectFit: 'contain',
  },
  smallImagesContainer: {
    display: 'flex',
    alignItems:'center',
    marginBottom: theme.spacing(2),
    gap:'32px',
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    maxHeight:'72px'
  },
  imageWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  status:{
    fontFamily:'Poppins',
    fontSize:'12px',
    fontWeight:400,
    color:'grey',
    position: 'absolute', 
    bottom: '-12px', 
    left: '50%',
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
  },
  root: {
    display: 'flex',
    padding: theme.spacing(5),
    
  },
  disableImage: {
    width: '90%',
    height: '100%',
    maxHeight:'508px'
    
  },
}))
