import React from 'react'
import DisablePage from '../DisablePage/DisablePage'

const FlyoutBlockPage = () => {
  const productInfo = {
    title: 'Your plan does not include Marketplace.',
    features: [
      'Ensure your product meets technical benchmarks and gain comprehensive vendor insights, all while integrating seamlessly with Marketplace APIs.',
      'Effortlessly prepare and list your product directly from your CRM or our UI.',
      'Efficiently manage the entire lifecycle of marketplace transactions.',
      "Tailor private offers (CPPOs & MPPOs) to meet buyer's needs with custom negotiated pricing, flexible payment schedules, and terms.",
      "Get notified to many crucial events, including listing updates, new orders, private offer acceptance and many more.",
    ],
    subheading: 'Navigate Cloud Commerce seamlessly with Labra Marketplace to get the following benefits:',
    link:'https://www.labra.io/product/flyout/',
  }
  return (
    <div>
      <DisablePage productInfo={productInfo} header={"Marketplace"} />
    </div>
  )
}

export default FlyoutBlockPage
