import React from 'react'
import DisablePage from '../DisablePage/DisablePage'

const OppsyncBlockPage = () => {
  const productInfo = {
    title: 'Your plan does not include Co-sell.',
    features: [
      'Our team is here to help you get started with co-selling alongside cloud hyperscalers, enabling you to effectively manage your sales pipeline and boost your revenue.',
      'Leverage a purchase-build, bi-directional CRM integration designed to drive  partner success with cloud hyperscalers.',
      'Effortlessly navigate co-selling processes with cloud hyperscalers, accelerating sales pipeline growth and fostering stronger partnerships.',
      'Stay informed about co-sell opportunities with real-time notifications on opportunity inception, changes in acceptance status, and much more.',
    ],
    subheading: 'Streamline your cloud co-selling journey with Labra.',
    link: 'https://www.labra.io/product/oppsync/',
  }
  return (
    <div>
      <DisablePage productInfo={productInfo} header={'Co-sell'} />
    </div>
  )
}

export default OppsyncBlockPage
