import React, { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faLock } from '@fortawesome/pro-regular-svg-icons'
import {
  ActiveProducts,
  PartnerData,
  Product,
} from '../../modules/partner/action'
import { useFlag, useFlagValue } from '@labrav/flags'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from '../../../store'
import { useOppSyncOnboardingStatus } from '../../../oppsync/utils/hooks/useOppSyncOnboardingStatus'
import { FlyOutListingData } from '../../../admin/modules/flyOutSyncs/reducer'
import { isLoading } from '../../utils/loadingState'
import { LoadingTypes } from '../../modules/loading/reducer'
import { UserProfileState } from '../../modules/userProfile/reducer'
import { Sections } from '../../../flyout/modules/flyOutOnboarding/reducer'
import {
  customerFormData,
  usePartnerId,
} from '../../utils/Hooks/usePartnerData'
import { errorLogger } from '../../utils/errorLogger'
import {
  SecondaryNavigationRoutesType,
  SecondarySubRouteType,
} from '@labrav/react-components/lib/@types/v3/components/SidePanel'
import { SidePanelV3 } from '@labrav/react-components'
import { UserProfile } from '../../modules/userProfile/action'
import { Role } from '../../utils/roles'
import {
  CoSellOnboardingRoutes,
  getAssessmentsRoutes,
  getCosellRoutes,
  getIntegrationsRoutes,
  getMarketing,
  getMarketplaceRoutes,
  getSalesIqRoutes,
  getSettingsRoutes,
  MarketplaceOnboardingRoutes,
  MarketplaceSeo,
  planLockDisabledProps,
  SalesIqPropensityRoute,
  StorefrontMetricsRoute,
  StorefrontRoute,
} from './SidePanelWrapperRoutesV3'
import { getIsFormCompleted } from '../SidePanelWrapperV2/SidePanelWrapperV2'
import { TeamMembersWrapper } from '../SidePanelWrapperV2/TeamMembersWrapper'
import { snakeToSentenceCase } from '../../utils/strings'
import { useTracker } from '../../utils/Tracker/hook'
interface SidePanelWrapperV3Props {
  isNotInProductSelection: boolean
  activeProducts: Product[]
}

const EndIcon = () => (
  <FontAwesomeIcon
    icon={faCircleCheck}
    data-testid="checkmark"
    style={{ height: '16px', width: '16px' }}
    color="green"
  />
)

const BlockIcon = () => (
  <FontAwesomeIcon
    icon={faLock}
    data-testid="lock"
    style={{ height: '16px', width: '14px' }}
    color="grey"
  />
)

export const SidePanelWrapperV3: React.FC<SidePanelWrapperV3Props> = ({
  activeProducts,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const partnerId = usePartnerId() || ''
  const tracker = useTracker()

  const sellerName = useSelector<string | undefined>(
    state => state.flyoutSellerInfo.awsAccountName
  )

  const trackEvent = async (
    heading: string,
    subHeading: string,
    partnerId: string,
    customerName: string
  ) => {
    const formattedHeading = heading.replace(/\s+/g, '-')
    const formattedSubHeading = subHeading.replace(/\s+/g, '-')
    tracker?.track({
      isSidePanelTracking: true,
      section: `${formattedHeading}`,
      component: `${formattedSubHeading}`,
      action: 'clicked',
      title: `${subHeading} viewed`,
      source: `${subHeading} page`,
      partnerId: `${partnerId}`,
      customerName: `${customerName}`,
    })
  }

  const { flag: isFlyoutStorefrontEnabled } = useFlag('flyoutStorefront')
  const { flag: isFlyoutStorefrontMetricsEnabled } = useFlag(
    'flyoutStorefrontMetrics'
  )
  const { flag: isPropensityEnabled } = useFlag('propensityFeature')
  const { flag: isMarketplaceSeoEnabled } = useFlag('marketplaceSeo')

  const { flag: isResellerAuthorizationEnable } = useFlag(
    'resellerAuthorization'
  )

  const { loading: isFlagCosellLoading, value: cosellEnabled } =
    useFlagValue('cosell')
  const { value: isCloudfaasFeatEnabled } = useFlagValue('cloudfaasFeat')
  const isCosellEnabled = !isFlagCosellLoading && cosellEnabled
  const { logout: auth0Logout } = useAuth0()

  const {
    onboardingComplete,
    hasCompanyInfo,
    loading: loadingOnboardingInfo,
    isNewPartner,
    hasArnRoleSetup,
    hasPolicySetup,
    hasConnectedCrmAtLeastOnce,
    hasSolutionsAndServices,
  } = useOppSyncOnboardingStatus(isCosellEnabled)

  const { partnerData, flyoutSellerInfo, isLoadingSeller } = useSelector<{
    flyoutSellerInfo: FlyOutListingData
    partnerData: undefined | PartnerData
    isLoadingSeller: boolean
  }>(state => ({
    flyoutSellerInfo: state.flyoutSellerInfo,
    partnerData: state.PartnerData.user.partnerData || undefined,
    isLoadingSeller:
      isLoading(state.loading)(LoadingTypes.MARKETPLACE_INFO) ||
      isLoading(state.loading)(LoadingTypes.FLYOUT_ONBOARDING_DATA),
  }))

  const customerData = customerFormData()
  const subscribedProducts = customerData.subscribedProducts

  const loadingFlyoutSellerInfo = Boolean(!partnerData || isLoadingSeller)
  const activeProductsFromUser = useSelector<ActiveProducts | undefined>(
    state => state.PartnerData.user.partnerData?.activeProducts
  )
  const { userProfile } = useSelector<UserProfileState>(
    state => state.userProfile
  )
  const flyOutOnboardingForm = useSelector<Sections>(
    state => state.flyOutOnboarding.onboardingForm
  )
  const user = useSelector<UserProfile | undefined>(
    state => state.userProfile.userProfile
  )
  const isAdmin =
    user?.roles?.some(r =>
      [Role.ADMIN, Role.SUPER_ADMIN, Role.ACCOUNT_EXECUTIVE].includes(r)
    ) || false

  const { crmType } = customerFormData()
  const partnerInviteEnabled = useFlagValue('partnerInvite')
  const isCrmLabra = crmType === 'labra'
  const showCrm = partnerInviteEnabled?.value ? isCrmLabra : false
  const isUserManaged = flyOutOnboardingForm?.labraPartnerDetails?.isManaged
  const product = activeProducts

  const globalState = useSelector(state => state)
  const logout = () => {
    try {
      localStorage.removeItem('onboardingWelcome')
    } catch (err) {
      console.warn('localStorage is not available.')
      errorLogger({ globalState })(err as Error)
      return undefined
    }

    localStorage.removeItem('auth0-access-token')
    auth0Logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const footerSubMenuRoutes: SecondarySubRouteType[] = [
    {
      key: 7,
      path: '/user/profile',
      name: 'Account information',
      disabled: false,
    },
    {
      key: 9,
      path: '',
      name: 'Logout',
      onClick: logout,
      disabled: false,
    },
  ]

  const navigateRoute = (path: string) => {
    if (path) {
      navigate(path)
    }
  }

  const getIcon = (showCrm: boolean, hasConnectedCrmAtLeastOnce: boolean) => {
    if (showCrm) {
      return BlockIcon
    }
    if (hasConnectedCrmAtLeastOnce) {
      return EndIcon
    }
    return undefined
  }

  const getOppSyncOnboardingRoutes = () => {
    const oppSyncOnboardingRoutesFiltered = {
      ...CoSellOnboardingRoutes,

      routes: CoSellOnboardingRoutes.routes.map(route => {
        if (hasCompanyInfo) {
          if (route.name === 'Cloud settings') {
            return {
              ...route,
              disabled: false,
              endIcon:
                hasArnRoleSetup && hasPolicySetup && hasSolutionsAndServices
                  ? EndIcon
                  : undefined,
            }
          }
          if (route.name === 'CRM settings') {
            return {
              ...route,
              disabled: false,
              endIcon: getIcon(showCrm, hasConnectedCrmAtLeastOnce),
            }
          }
          if (route.name === 'Company details') {
            return { ...route, disabled: false, endIcon: EndIcon }
          }
          return { ...route, disabled: false }
        } else {
          if (route.name === 'CRM settings') {
            return {
              ...route,
              disabled: true,
              endIcon: getIcon(showCrm, hasConnectedCrmAtLeastOnce),
            }
          } else if (route.name === 'Cloud settings') {
            return {
              ...route,
              disabled: true,
              endIcon:
                hasArnRoleSetup && hasPolicySetup && hasSolutionsAndServices
                  ? EndIcon
                  : undefined,
            }
          }
          return route
        }
      }),
    }
    return oppSyncOnboardingRoutesFiltered
  }

  const getFlyoutOnboardingRoutes = () => {
    const flyoutOnboardingRoutesFiltered = {
      ...MarketplaceOnboardingRoutes,
      miscellaneousRoutes: [
        {
          key: '1',
          path: '/flyout/onboarding/aws-requirements',
          name: 'Check AWS requirements',
          disabled: false,
        },
      ],
      routes: MarketplaceOnboardingRoutes.routes
        .filter(route =>
          isUserManaged ? !route.path?.includes('cloud-settings') : true
        )
        .map(route => {
          const isOnboardingFormCompleted = getIsFormCompleted(
            route.path || '',
            flyOutOnboardingForm
          )
          const onboardingRoute = {
            ...route,
            ...(isOnboardingFormCompleted
              ? {
                  endIcon: EndIcon,
                }
              : {}),
          }

          return onboardingRoute
        }),
    }
    return flyoutOnboardingRoutesFiltered
  }

  const { pathname } = useLocation()
  const isOnboardingRoute = pathname.split('/').includes('onboarding')
  const isOppsyncOnboardingRoute =
    pathname.split('/').includes('onboarding') &&
    pathname.split('/').includes('oppsync')

  const isFlyoutOnboardingRoute =
    pathname.split('/').includes('onboarding') &&
    pathname.split('/').includes('flyout')

  const isMarketingRoute =
    pathname.split('/').includes('search-ranking') ||
    pathname.split('/').includes('storefront') ||
    pathname.split('/').includes('storefront-metrics')
  const isFlyoutRoute =
    pathname.split('/').includes('flyout') && !isMarketingRoute
  const isSalesIqRoute = pathname.split('/').includes('propensity')
  const isOppsyncRoute =
    pathname.split('/').includes('oppsync') && !isSalesIqRoute
  const isAssessmentsRoute = pathname.split('/').includes('cloudfaas')
  const isIntegrationsRoute =
    pathname.split('/').includes('integrations') ||
    pathname.split('/').includes('crm')
  const isSettingsRoute =
    pathname.split('/').includes('app-settings') ||
    pathname.split('/').includes('users')

  const { OPPSYNC: oppsyncProduct } = subscribedProducts
  const isOppsyncSubscribed = !partnerInviteEnabled?.value || !!oppsyncProduct

  const { FLYOUT: flyoutProduct } = subscribedProducts
  const isFlyoutSubscribed = !partnerInviteEnabled?.value || !!flyoutProduct

  const { CLOUDFAAS: cloudFaasProduct } = subscribedProducts
  const isCloudFaaSubscribed =
    !partnerInviteEnabled?.value || !!cloudFaasProduct

  const isFlyoutOnboarded = flyoutSellerInfo?.onboarded === true
  const isOppsyncOnboarded =
    product?.includes('oppsync') &&
    !isOnboardingRoute &&
    (onboardingComplete || !isNewPartner)

  const isProductSelectorRoute = pathname
    .split('/')
    .includes('product-selection')

  const username = `${userProfile?.givenName || ''} ${
    userProfile?.familyName || ''
  }`.trim()

  const getCompletePercentage = (obj: Record<string, boolean>) => {
    if (!obj) {
      return 0
    }
    const pageMarkerLength = Object.keys(obj).length
    const pagesCompletedLength = Object.values(obj).filter(p => !!p).length
    const percentage = (pagesCompletedLength / pageMarkerLength) * 100
    return Math.round(percentage)
  }

  const obj = {
    hasCompanyInfo,
    hasArnRoleSetup,
    hasPolicySetup,
    hasSolutionsAndServices,
    ...(showCrm === false && { hasConnectedCrmAtLeastOnce }),
  }

  const completePercentage = useMemo(() => getCompletePercentage(obj), [obj])

  const isMarketingModuleShow =
    isMarketplaceSeoEnabled?.value ||
    isFlyoutStorefrontEnabled?.value ||
    isFlyoutStorefrontMetricsEnabled?.value
  const updatedMarketingNavigationRoutes = isMarketingModuleShow
    ? [
        ...(isMarketplaceSeoEnabled?.value ? MarketplaceSeo : []),
        ...(isFlyoutStorefrontEnabled?.value ? StorefrontRoute : []),
        ...(isFlyoutStorefrontMetricsEnabled?.value
          ? StorefrontMetricsRoute
          : []),
      ]
        .sort(
          (
            navigateRouteOne: SecondarySubRouteType,
            navigateRouteTwo: SecondarySubRouteType
          ) => navigateRouteOne.key - navigateRouteTwo.key
        )
        .map(route => ({
          ...route,
          onClick: () =>
            trackEvent(
              'Marketing',
              `${route.name}`,
              partnerId,
              sellerName || ''
            ),
          ...(!isFlyoutSubscribed && route?.keyName === 'STOREFRONT'
            ? { ...planLockDisabledProps, disabled: true }
            : {}),
        }))
    : []

  const marketplaceRoutes = getMarketplaceRoutes(
    subscribedProducts?.FLYOUT?.features || [],
    isFlyoutOnboarded,
    isFlyoutSubscribed,
    activeProducts,
    isFlyoutRoute,
    partnerId,
    sellerName || '',
    trackEvent,
    { isResellerAuthorizationEnable: isResellerAuthorizationEnable?.value }
  )
  const coSellRoutes = getCosellRoutes(
    subscribedProducts?.OPPSYNC?.features || [],
    isOppsyncOnboarded,
    isOppsyncSubscribed,
    activeProducts,
    isOppsyncRoute,
    isFlyoutSubscribed,
    partnerId,
    sellerName || '',
    trackEvent
  )
  const salesIqRoutes = isPropensityEnabled?.value
    ? [
        {
          ...getSalesIqRoutes(subscribedProducts?.SALES?.features || [])[0],
          subRoutes: SalesIqPropensityRoute.map(salesIqPropensityItem => ({
            ...salesIqPropensityItem,
            onClick: () =>
              trackEvent(
                'Sales IQ',
                `${salesIqPropensityItem.name} Clicked`,
                partnerId,
                sellerName || ''
              ),
          })),
          byDefault: isSalesIqRoute,
        },
      ]
    : []

  const marketingRoutes = isMarketingModuleShow
    ? [
        {
          ...getMarketing(subscribedProducts?.MARKETING?.features || [])[0],
          subRoutes: updatedMarketingNavigationRoutes, //remove this when salses live without flag as feature
          byDefault: isMarketingRoute,
        },
      ]
    : []
  const assessmentRoutes = isCloudfaasFeatEnabled
    ? getAssessmentsRoutes(
        subscribedProducts?.CLOUDFAAS?.features || [],
        isAssessmentsRoute,
        partnerId,
        sellerName || '',
        trackEvent
      )
    : []
  const integrationRoutes = getIntegrationsRoutes(
    subscribedProducts?.INTEGRATIONS?.features || [],
    crmType,
    isIntegrationsRoute,
    partnerId,
    sellerName || '',
    trackEvent
  )
  const settingsRoute = getSettingsRoutes(
    isSettingsRoute,
    partnerId,
    sellerName || '',
    trackEvent
  )
  const allRoutes: SecondaryNavigationRoutesType[] = [
    ...marketplaceRoutes,
    ...coSellRoutes,
    ...salesIqRoutes,
    ...marketingRoutes,
    ...assessmentRoutes,
    ...integrationRoutes,
    ...settingsRoute,
  ]

  const shouldShowOppsyncOnbaordRoute =
    product?.includes('oppsync') && !isOppsyncOnboarded && isOppsyncSubscribed

  const shouldShowFlyoutOnbaordRoute =
    product?.includes('flyout') && !isFlyoutOnboarded && isFlyoutSubscribed

  const finalRoutes: {
    title: string
    routes: SecondaryNavigationRoutesType[]
    miscellaneousRoutes?: SecondaryNavigationRoutesType[]
  } = useMemo(() => {
    if (isAdmin || isProductSelectorRoute) {
      return { title: '', routes: [] }
    }

    if (isOppsyncOnboardingRoute) {
      if (shouldShowOppsyncOnbaordRoute) {
        return getOppSyncOnboardingRoutes()
      }
    }

    if (isFlyoutOnboardingRoute) {
      if (shouldShowFlyoutOnbaordRoute) {
        return getFlyoutOnboardingRoutes()
      }
    }

    if (
      !isAdmin &&
      ((product?.includes('oppsync') &&
        isOppsyncSubscribed &&
        isOppsyncOnboarded) ||
        (product?.includes('flyout') &&
          isFlyoutSubscribed &&
          isFlyoutOnboarded) ||
        (isCloudfaasFeatEnabled &&
          product?.includes('cloudfaas') &&
          isCloudFaaSubscribed))
    ) {
      return { title: '', routes: allRoutes }
    }

    if (shouldShowOppsyncOnbaordRoute) {
      return getOppSyncOnboardingRoutes()
    }

    if (shouldShowFlyoutOnbaordRoute) {
      return getFlyoutOnboardingRoutes()
    }
    return { title: '', routes: [] }
  }, [
    product,
    onboardingComplete,
    hasCompanyInfo,
    activeProductsFromUser?.length,
    flyOutOnboardingForm,
    isOnboardingRoute,
    isProductSelectorRoute,
    loadingOnboardingInfo,
    hasSolutionsAndServices,
    hasConnectedCrmAtLeastOnce,
    loadingFlyoutSellerInfo,
    isFlyoutStorefrontEnabled,
    isFlyoutStorefrontMetricsEnabled,
    isFlyoutOnboarded,
    isOppsyncOnboarded,
    isCloudFaaSubscribed,
    shouldShowOppsyncOnbaordRoute,
    shouldShowFlyoutOnbaordRoute,
    allRoutes,
    isFlyoutRoute,
    isOppsyncRoute,
  ])

  const maxHeight =
    isOppsyncOnboardingRoute || isFlyoutOnboardingRoute ? '30vh' : '70vh'

  return (
    <SidePanelV3
      title={finalRoutes.title || ''}
      openDrawer={isOnboardingRoute ? isOnboardingRoute : undefined}
      {...(isOppsyncOnboardingRoute && isOppsyncSubscribed
        ? { showProgress: true }
        : false)}
      {...(isOppsyncOnboardingRoute && isOppsyncSubscribed
        ? { progressCount: completePercentage }
        : {})}
      maxHeight={maxHeight}
      activeLink={location.pathname}
      routes={finalRoutes.routes}
      navigateRoute={navigateRoute}
      middleSection={TeamMembersWrapper}
      footerSubMenuRoutes={footerSubMenuRoutes}
      miscellaneousRoutes={finalRoutes.miscellaneousRoutes}
      userInfo={{
        name: username === '' ? '-' : username,
        roles: userProfile.roles?.map(snakeToSentenceCase) || [],
      }}
    />
  )
}
