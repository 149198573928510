import { Reducer } from 'redux'
import { SubscriptionsActionTypes } from './action'
import {
  Dimension,
  EulaType,
  Installments,
  OfferRecipients,
  OfferStakeholders,
  OfferStatus,
  OfferTypeRenew,
} from '../privateOffer/reducer'

export enum SubscriptionStatusValue {
  SUBSCRIPTION_PENDING = 'SUBSCRIPTION_PENDING',
  SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS',
  SUBSCRIPTION_FAIL = 'SUBSCRIPTION_FAIL',
  UNSUBSCRIBE_PENDING = 'UNSUBSCRIBE_PENDING',
  UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS',
}

export interface SubscriptionObject {
  cloudMarketplaceContractId: string
  subscriptionId: string
  awsAgreementId: string
  buyerCompanyName: string
  buyerId: string
  buyerLogoUrl: string
  lastMeteringInfoSentAt: string
  contractStartDate: string
  contractExpirationDate: string
  listingStatus: string
  status: SubscriptionStatusValue
  productType: string
  createdAt: string
  updatedAt: string
  offerType: string
  awsOfferId?: string
  cloudMarketplaceProductId: string
  awsProductUid: string
  productId: string
  productName: string
  productUrl: string
  cloudMarketplace: string
  cloudOfferUrl?: string
  labraOfferUrl?: string
  offerAcceptedAt: string
  renewal: boolean
  entitlement: Entitlement
  privateOfferName?: string
  awsAccountId?: string
  productStatus?: string
}

export interface Entitlement {
  rows: Row[]
}

export interface Row {
  entitlementId: string
  entitlementValue: string
  entitlementExpirationDate: string
  dimensionId: string
  dimensionName: string
  dimensionLabel: string
  dimensionLabelDescription: string
  dimensionIsAdditionalUsageAllowed: boolean
  dimensionAdditionalUsageDescription: string
  dimensionValueDataType: string
  meteringTimestamp: string
  meteringId: string
  meteringQuantityConsumed: number
  meteringLastUpdatedAt: string
  meteringStatus: string
}

interface SubscriptionsAPIResponse {
  pageNumber: number
  pageSize: number
  count: number
  sortBy?: string
  sortOrder?: string
}

export interface SubscriptionDetailObject {
  subscription: Subscription
  entitlements: EntitlementSubs[]
  buyerDetails: BuyerDetails
  paymentSchedule: PaymentSchedule[]
  offerDetails: AgreementBasedOffer
  productDetails: ProductDetails
}

export interface BuyerDetails {
  companyName: string
  email: string
}

export interface EntitlementSubs {
  value: string
  isAdditionalUsageAllowed?: boolean
  expirationDate: string
  dimensionId: string
  price: number
  dimensionLabel: string
  dimensionName: string
  labelDescription: string
  entitlementId: string
  quantity: string
  crmObjectId: string
  currency: string
}

export interface PaymentSchedule {
  amount: number
  currency: string
  paymentDate: string
}

export interface ProductDetails {
  labraProductId: string
  productCode: string
  dimensionCategoryName: string
  productName: string
  productType: string
  labraSellerId: string
}

export interface Subscription {
  id: string
  status: SubscriptionStatusValue
  createdAt: string
  expirationDate: string
  autoRenew: boolean
  cloudAccountId: string
  tcv: number
  awsAgreementId: string
}

export type AgreementBasedOfferWithSaveData = {
  hasBeenSent: boolean
  noOfTimesSaved: number
}
export type AgreementBasedOffer = AgreementBasedOfferData &
  AgreementBasedOfferWithSaveData

export interface SubscriptionsState extends SubscriptionsAPIResponse {
  subscriptions: SubscriptionObject[]
  noOfPage: number | null
  currentSubscription: { [x: string]: SubscriptionDetailObject }
  currentAgreementInCreation: { [x: string]: AgreementBasedOffer }
}

export interface AgreementBasedOfferData {
  privateOfferId: string
  labraOfferId: string
  awsAgreementId?: string
  productName: string
  offerStatus: OfferStatus
  awsAccountId?: string
  privateOfferName: string
  flexiblePaymentTerms: boolean
  eulaType: EulaType
  eulaFile?: string
  offerExpirationDate: string
  offerRecipients: OfferRecipients[]
  companyName: string
  installments?: Installments[]
  subscriptionEndDate?: string | null
  emailCustomMessage?: string
  awsProductId?: string
  dimensions?: Dimension[]
  createdAt: string
  draft?: boolean
  crmOpportunityId?: string
  cloudOfferId?: string
  offerStakeholders?: OfferStakeholders[]
  type?: OfferTypeRenew
  previousSubscriptionEndDate?: string
  renewal?: boolean
  renewalChannel?: string
}

const initialState: SubscriptionsState = {
  subscriptions: [],
  pageNumber: 0,
  noOfPage: null,
  pageSize: 10,
  count: 0,
  sortBy: '',
  sortOrder: 'desc',
  currentSubscription: {},
  currentAgreementInCreation: {},
}

type actionType =
  | {
      type: SubscriptionsActionTypes.SET_SUBSCRIPTIONS_DATA
      payload: { data: SubscriptionObject[] } & SubscriptionsAPIResponse
    }
  | {
      type: SubscriptionsActionTypes.SUBSCRIPTIONS_SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: SubscriptionsActionTypes.CLEAR_SUBSCRIPTIONS_DATA
    }
  | {
      type: SubscriptionsActionTypes.SET_CURRENT_SUBSCRIPTION_DETAILS
      payload: SubscriptionDetailObject
    }
  | {
      type: SubscriptionsActionTypes.CLEAR_CURRENT_SUBSCRIPTION_DETAILS
    }
  | {
      type: SubscriptionsActionTypes.SET_INPROGRESS_AGREEMENT_BASED_OFFER
      payload: {
        subscriptionId: string
        data: AgreementBasedOffer
        noOfTimesSaved: number
      }
    }
  | {
      type: SubscriptionsActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_AGREEMENT_BASED_OFFER
      payload: string
    }
  | {
      type: SubscriptionsActionTypes.UPDTE_AGREEMENT_BASED_OFFER_HAS_BEEN_SENT
      payload: { hasBeenSent: boolean; subscriptionId: string }
    }
  | {
      type: SubscriptionsActionTypes.CLEAR_INPROGRESS_AGREEMENT_BASED_OFFER
      payload: string
    }

export const reducer: Reducer<SubscriptionsState, actionType> = (
  state: SubscriptionsState = initialState,
  action
) => {
  switch (action.type) {
    case SubscriptionsActionTypes.SET_SUBSCRIPTIONS_DATA: {
      const existingIds = new Set(
        state.subscriptions.map(sub => sub.subscriptionId)
      )
      const newSubscriptions = action.payload.data.filter(
        sub => !existingIds.has(sub.subscriptionId)
      )

      return {
        ...state,
        subscriptions: state.subscriptions.concat(newSubscriptions),
        pageNumber: action.payload.pageNumber,
        count: action.payload.count,
        noOfPage: Math.ceil(
          (action.payload.count ?? 0) / action.payload.pageSize
        ),
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      }
    }
    case SubscriptionsActionTypes.SUBSCRIPTIONS_SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case SubscriptionsActionTypes.CLEAR_SUBSCRIPTIONS_DATA: {
      return {
        ...state,
        subscriptions: [],
        noOfPage: null,
        count: 0,
        pageNumber: 0,
      }
    }
    case SubscriptionsActionTypes.SET_CURRENT_SUBSCRIPTION_DETAILS: {
      return {
        ...state,
        currentSubscription: {
          [action.payload.subscription.id]:
            action.payload as SubscriptionDetailObject,
        },
      }
    }
    case SubscriptionsActionTypes.CLEAR_CURRENT_SUBSCRIPTION_DETAILS: {
      return {
        ...state,
        currentSubscription: {},
      }
    }
    case SubscriptionsActionTypes.SET_INPROGRESS_AGREEMENT_BASED_OFFER: {
      return {
        ...state,
        currentAgreementInCreation: {
          ...state.currentAgreementInCreation,
          [action.payload.subscriptionId]: {
            ...(action.payload.data as AgreementBasedOffer),
            hasBeenSent: false,
            noOfTimesSaved: action.payload.noOfTimesSaved,
          },
        },
      }
    }
    case SubscriptionsActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_AGREEMENT_BASED_OFFER: {
      const subscriptionId = action.payload
      const noOfTimesSaved =
        state.currentAgreementInCreation[subscriptionId].noOfTimesSaved + 1
      return {
        ...state,
        currentAgreementInCreation: {
          ...state.currentAgreementInCreation,
          [subscriptionId]: {
            ...state.currentAgreementInCreation[subscriptionId],
            noOfTimesSaved,
          },
        },
      }
    }
    case SubscriptionsActionTypes.UPDTE_AGREEMENT_BASED_OFFER_HAS_BEEN_SENT: {
      const { subscriptionId, hasBeenSent } = action.payload
      return {
        ...state,
        currentAgreementInCreation: {
          ...state.currentAgreementInCreation,
          [subscriptionId]: {
            ...state.currentAgreementInCreation[subscriptionId],
            hasBeenSent,
          },
        },
      }
    }
    case SubscriptionsActionTypes.CLEAR_INPROGRESS_AGREEMENT_BASED_OFFER: {
      const subscriptionId = action.payload
      const agreementBasedOffers = { ...state.currentAgreementInCreation }
      delete agreementBasedOffers[subscriptionId]
      return {
        ...state,
        currentAgreementInCreation: {
          ...agreementBasedOffers,
        },
      }
    }

    default:
      return state
  }
}
