import { AnyAction } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../store'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { getSingleResaleAuthorization } from '../../api/markeplace'
import { camelize } from 'casing'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'

export enum ReceivedResellerAuthorizationActionTypes {
  SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION = 'SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION',
  CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION = 'CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION',
}

export const setCurrentReceivedResaleAuthorization = (
  data: Record<string, unknown>
) =>
  ({
    type: ReceivedResellerAuthorizationActionTypes.SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION,
    payload: data,
  } as unknown as AnyAction)

export const clearCurrentReceivedResaleAuthorization = () =>
  ({
    type: ReceivedResellerAuthorizationActionTypes.CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION,
  } as unknown as AnyAction)

export const getCurrentReceivedResaleAuthorization =
  (
    partnerId: string,
    resaleAuthorizationId: string,
    crmOpportunityId?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(clearCurrentReceivedResaleAuthorization())
    try {
      const { data } = await getSingleResaleAuthorization(
        partnerId,
        resaleAuthorizationId,
        crmOpportunityId
      )
      dispatch(setCurrentReceivedResaleAuthorization(camelize(data)))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
