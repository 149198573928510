import { theme } from '../../../../theme'
import { ListingType } from '../listingCardCreation/reducer'
import { TemplateCreationActionTypes } from './action'
import { Reducer } from 'redux'

export interface StringCustomization {
  fontSize: string
  fontWeight: number
  color: string
  verticalDeviderColor?: string
  buyWithAwsLogoUrlAlignment?: string
  textWrap?: string
}
export interface CardHeader {
  customization: {
    [key: string]: any
  }
  shouldShowLogoInCardHeader: boolean
  title?: string
}

export interface ButtonCustomization {
  backgroundColor?: string
  borderColor?: string
  color?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: string
}

export interface CardCustomization {
  backgroundColor: string
  borderColor: string
  borderRadius: string
  borderStrokeWidth: string
  cardShadow?: boolean
  border?: string
  cardAlignment?: string
  linkColor?: any
  cardOrientation?: string
  minimumDescriptionLength: number
  font?: string
  fontSize?: string
  flex?: string
  cardSize?: number
  borderStyle?: string
  minWidth?: string
  paddingRight?: string
}

export interface ButtonType {
  customization: ButtonCustomization
  title: string
  key: string
  visibility?: boolean
  fieldType?: 'Outlined' | 'Filled'
}

export interface CardType {
  customization: CardCustomization
  view: string
  badges: string[]
  badgesCustomization?: { [key: string]: any }
  cardContentAlign?: string
}

export interface keysToDisplay {
  id?: string
  apiName: string
  displayName: string
  isRequired: boolean
  dataType: string
  validationType?: string | null
  minLength: number
  maxLength: number
  isExisting?: boolean
}

export interface FormType {
  title: string
  fieldsToDisplay: keysToDisplay[]
  link?: string
}
export interface ProductType {
  customization: StringCustomization
}

export interface CardContentType {
  customization: StringCustomization
}

export interface IntegrationData {
  id?: string | null
  canvasColor?: string
  name?: string
  type?: ListingType
  card?: CardType
  buttons?: ButtonType[]
  productTitle?: ProductType
  privateOfferForm?: FormType
  requestDemoForm?: FormType
  buyWithAwsLogoUrl?: string
  cardContent?: CardContentType
  cardBodyContent?: CardContentType
  cardHeader?: CardHeader
  outlinedButton?: ButtonCustomization
  filledButton?: ButtonCustomization
}
export interface TemplateInCreation {
  integrationData: IntegrationData
}

export const initialState: TemplateInCreation = {
  integrationData: {
    id: null,
    canvasColor: '#DFE4EF',
    name: '',
    card: {
      customization: {
        backgroundColor: '#FFFFFF',
        borderColor: '#000000',
        borderRadius: '4px',
        borderStrokeWidth: '1px',
        cardShadow: false,
        border: '',
        borderStyle: 'solid',
        cardAlignment: 'center',
        cardOrientation: 'vertical',
        linkColor: '',
        minimumDescriptionLength: 0,
        font: 'sans-serif',
        fontSize: '18px',
        flex: '',
        cardSize: 0,
      },
      view: '',
      badges: ['Vendor Insights', 'ratings'],
      badgesCustomization: {
        showReviews: false,
        showInsights: false,
        backgroundColor: '#ECEFF5',
        color: '#6A6A6A',
      },
      cardContentAlign: 'center',
    },
    buttons: [
      {
        customization: {
          fontSize: '20px',
          fontWeight: '400',
          color: theme.palette.button.primary,
          backgroundColor: theme.palette.button.secondary,
        },
        title: 'Buy with AWS',
        key: 'buy_with_aws',
        visibility: true,
        fieldType: 'Filled',
      },
      {
        customization: {},
        title: 'View on AWS Marketplace',
        key: 'open_listing',
        visibility: false,
        fieldType: 'Outlined',
      },
      {
        customization: {
          fontSize: '20px',
          fontWeight: '400',
          color: theme.palette.button.primary,
          borderColor: theme.palette.textField.light,
        },
        title: 'Request a private offer',
        key: 'request_private_offer',
        visibility: true,
        fieldType: 'Outlined',
      },
      {
        customization: {},
        title: 'Request a demo',
        key: 'request_demo',
        visibility: false,
        fieldType: 'Outlined',
      },
      {
        customization: {},
        title: 'Try for free',
        key: 'free_trial',
        visibility: false,
        fieldType: 'Filled',
      },
    ],
    productTitle: {
      customization: {
        fontSize: '26px',
        color: '#000000',
        fontWeight: 400,
        verticalDeviderColor: '',
        buyWithAwsLogoUrlAlignment: '',
        textWrap: '',
      },
    },
    privateOfferForm: {
      title: 'Request a private offer',
      fieldsToDisplay: [],
      link: '',
    },
    requestDemoForm: {
      title: 'Request a demo',
      fieldsToDisplay: [],
      link: '',
    },
    buyWithAwsLogoUrl: 'https://assets.flyout.labra.io/misc/aws_available.png',
    cardContent: {
      customization: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#6A6A6A',
        verticalDeviderColor: '#00000021',
        buyWithAwsLogoUrlAlignment: '',
        textWrap: '',
      },
    },
    cardBodyContent: {
      customization: {
        fontSize: '12px',
        fontWeight: 400,
        color: '',
        verticalDeviderColor: '',
        buyWithAwsLogoUrlAlignment: '',
        textWrap: '',
      },
    },
    cardHeader: {
      shouldShowLogoInCardHeader: false,
      customization: {
        color: '#000000',
        fontSize: '12px',
        fontWeight: 400,
      },
      title:
        'Purchase this listing in AWS Marketplace using your AWS account. In AWS Marketplace, you can quickly launch pre-configured software with just a few clicks. AWS handles billing and payments, and charges appear on your AWS bill.',
    },
    outlinedButton: {
      fontSize: '20px',
      fontWeight: '400',
      color: theme.palette.button.primary,
      borderColor: theme.palette.textField.light,
    },
    filledButton: {
      fontSize: '20px',
      fontWeight: '400',
      color: theme.palette.button.primary,
      backgroundColor: theme.palette.button.secondary,
    },
    type: ListingType.LISTING_CARD,
  },
}

type actionType =
  | {
      type: TemplateCreationActionTypes.SET_CREATE_TEMPLATE_DATA
      payload: {
        integrationData: TemplateInCreation
      }
    }
  | {
      type: TemplateCreationActionTypes.UPDATE_TEMPLATE_DATA
      payload: {
        integrationId: string
        integrationData: Record<string, string>
      }
    }
  | {
      type: TemplateCreationActionTypes.CLEAR_CURRENT_TEMPLATE_DATA
    }

export const reducer: Reducer<TemplateInCreation, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TemplateCreationActionTypes.SET_CREATE_TEMPLATE_DATA: {
      return {
        ...state,
        integrationData: {
          ...state.integrationData,
          ...action.payload,
        },
      }
    }
    case TemplateCreationActionTypes.CLEAR_CURRENT_TEMPLATE_DATA:
      return initialState

    default: {
      return state
    }
  }
}
