import { ButtonInCreation, ButtonsData } from './reducer'
import { AnyAction, Dispatch } from 'redux'
import { getErrorMessages } from '../../../../common/utils/error'
import {
  DefaultSuccesrOnSubmitData,
  RequestFailureMessage,
} from '../../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { v4 as uuid4 } from 'uuid'
import { isAdminRole } from '../../../../common/utils/isAdminRole'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { errorLogger } from '../../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../../store'
import {
  getCurrentTemplateData,
  getListingDataById,
  getProductDataFromLink,
  putButtonTemplateData,
  putTemplateData,
} from '../../../api/markeplace'
import { setTemplateOnListing } from '../listingCardCreation/action'
import { AxiosResponse } from 'axios'
import { PartnerType } from '../../../../common/modules/partner/action'
import { BWAProduct, BWAProductGet } from '../listingCardCreation/reducer'
import { awsMarketplaceProductLink } from '../../../../common/utils/constants'
import { camelize } from 'casing'

export enum ButtonCreationActionTypes {
  SET_CREATE_BUTTON_DATA = 'SET_CREATE_BUTTON_DATA',
  UPDATE_BUTTON_DATA = 'UPDATE_BUTTON_DATA',
  CLEAR_CURRENT_BUTTON_DATA = 'CLEAR_CURRENT_BUTTON_DATA',
}

export const geButtonById =
  (integrationId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.BUTTON_STOREFRONT_LOADING))
    await dispatch(clearButtonData())
    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      let response = await getListingDataById(partnerId, integrationId)
      let { data } = response
      const updatedData = transformDataFromGet(data)
      dispatch(setButtonFormData(updatedData))
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch details for the listing.',
      ])(e as AxiosResponse<ErrorResponse>)

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.BUTTON_STOREFRONT_LOADING))
    }
  }

export const transformDataFromGet = (data: Record<string, unknown>) => {
  const { product, id, template, name, type, ...rest } = data
  let updatedProduct: BWAProduct[] =
    Object.keys(product as BWAProductGet).map((key: string) => {
      return {
        ...(product as BWAProductGet)[key],
        url: `${awsMarketplaceProductLink}/${key}`,
        isOpen: true,
        uniqueId: uuid4(),
        isLoading: false,
      }
    }) || []
  if (updatedProduct.length === 0) {
    updatedProduct.push({
      uniqueId: String(uuid4()),
      url: '',
      isOpen: true,
      isLoading: false,
    }) as unknown as BWAProduct[]
  }
  const upudatedButtonData = {
    buttonData: {
      ...camelize((template as any).data),
      name: name,
      type: type,
      id: (template as any).id,
    },
  }
  return {
    ...upudatedButtonData.buttonData,
    product: updatedProduct,
    integrationId: id,
  } as ButtonsData
}
export const saveButtonStyleData =
  (buttonData: ButtonInCreation) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { id, name, type, product, ...restData } = buttonData.buttonData
      const cards = product.map(prod => {
        const match = prod.url.match(/prodview-[a-zA-Z0-9]+/)
        return match ? match[0] : null
      })
      const updatedData = {
        id: id,
        data: {
          ...restData,
        },
        type: type,
        integration_data: {
          integration_id: getState().buttonCreation.buttonData.integrationId,
          cards: cards,
          type: type,
          name: name,
        },
      }
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const { data } = await putButtonTemplateData(partnerId, updatedData)
      const getTemplateId = data?.button_id || null
      const getIntegrationId = data?.integration_id || null
      const updatedTemplateData = {
        ...buttonData.buttonData,
        id: getTemplateId,
        ...data,
        integrationId:
          getIntegrationId ||
          getState().buttonCreation.buttonData.integrationId,
      }
      await dispatch(setButtonFormData(updatedTemplateData))
      await dispatch(
        updateAppAlert({
          message: DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      if (data.template_id) {
        return data.template_id
      }
      if (data.integration_id) {
        return data.integration_id
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const fetchProductDetailsFromUrlButtons =
  (productId: string, index: number, buttonData: ButtonInCreation) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.PRODUCT_INFORMATION))
    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      let response = await getProductDataFromLink(partnerId, productId)
      let { data } = response
      buttonData.buttonData.product[index] = {
        ...buttonData.buttonData.product[index],
        product_id: productId,
        isLoading: false,
        data: { ...data.product_data },
      }
      dispatch(setButtonFormData(buttonData.buttonData))
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch details for the provided url.',
      ])(e as AxiosResponse<ErrorResponse>)

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.PRODUCT_INFORMATION))
    }
  }

export const setButtonFormData = (integrationData: ButtonsData) => {
  return {
    type: ButtonCreationActionTypes.SET_CREATE_BUTTON_DATA,
    payload: integrationData,
  }
}
export const clearButtonData = () =>
  ({
    type: ButtonCreationActionTypes.CLEAR_CURRENT_BUTTON_DATA,
  } as unknown as AnyAction)
