import { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store'
import { Customer } from './reducer'
import { APIReferralError } from '../../../externalApps/api/errorHandlers'
import { errorLogger } from '../../../common/utils/errorLogger'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  patchCustomerDetails,
  postCustomerDetails,
} from '../../../common/api/Customers'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { Dispatch } from 'redux'

export enum NewCustomerActions {
  UPDATE_NEW_CUSTOMER_FORM = 'UPDATE_NEW_CUSTOMER_FORM',
  RESET_NEW_CUSTOMER_FORM = 'RESET_NEW_CUSTOMER_FORM',
}

export const updateNewCustomer = (data: Customer) => ({
  type: NewCustomerActions.UPDATE_NEW_CUSTOMER_FORM,
  payload: data,
})

export const resetForm = () => ({
  type: NewCustomerActions.RESET_NEW_CUSTOMER_FORM,
})

export const submitCustomerForm =
  (customer: Customer) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      await postCustomerDetails(customer)
      await dispatch(
        updateAppAlert({
          message: 'New customer created successfully',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      return { success: true }
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: error?.response?.data?.message || RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    }
  }

export const updateCustomerForm =
  (customer: Customer, partnerId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const activeProducts =
      getState().PartnerData.admin.partnerData?.activeProductsWithCloud || []

    try {
      const updatedData = {
        companyName: customer.companyName,
        internalNotes: customer.internalNotes,
        subscribedProducts: customer.subscribedProducts,
        numberOfSeats: customer.numberOfSeats,
        isInternal: customer.isInternal,
        activeProducts: activeProducts,
        crmType: customer.crmType,
        partnershipType: customer.partnershipType,
      }
      await patchCustomerDetails(partnerId, updatedData)
      await dispatch(
        updateAppAlert({
          message: 'Customer updated successfully',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    }
  }
