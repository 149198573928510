import { TemplateInCreation } from './reducer'
import { AnyAction, Dispatch } from 'redux'
import { getErrorMessages } from '../../../../common/utils/error'
import {
  DefaultSuccesrOnSubmitData,
  RequestFailureMessage,
} from '../../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { errorLogger } from '../../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../../store'
import {
  getCurrentTemplateData,
  putTemplateData,
} from '../../../api/markeplace'
import { setTemplateOnListing } from '../listingCardCreation/action'

export enum TemplateCreationActionTypes {
  SET_CREATE_TEMPLATE_DATA = 'SET_CREATE_TEMPLATE_DATA',
  UPDATE_TEMPLATE_DATA = 'UPDATE_TEMPLATE_DATA',
  CLEAR_CURRENT_TEMPLATE_DATA = 'CLEAR_CURRENT_TEMPLATE_DATA',
}

export const fetchCurrentTemplate =
  (templateId: string, setOnListing = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.CREATE_TEMPLATE))
    await dispatch(clearTemplateData())

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const response = await getCurrentTemplateData(partnerId, templateId)
      const { data, name, id } = response.data
      const updatedData = {
        name: name,
        id: id,
        ...data,
      }
      await dispatch(setTemplateFormData(updatedData))
      if (setOnListing) {
        await dispatch(setTemplateOnListing(updatedData))
      }
      await dispatch(
        updateAppAlert({
          message: '',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CREATE_TEMPLATE))
    }
  }
export const saveTemplateStyleData =
  (templateData: TemplateInCreation) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { id, name, card, type, ...restData } = templateData.integrationData
      const { customization, ...restCardData }: any = card
      const { minWidth, paddingRight, ...restCustomizeData } = customization
      const updatedData = {
        id: id,
        name: name,
        type: type,
        data: {
          ...restData,
          card: {
            ...restCardData,
            customization: {
              ...restCustomizeData,
            },
          },
        },
      }
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const { data } = await putTemplateData(partnerId, updatedData)
      const getTemplateId = data?.template_id || null
      const updatedTemplateData = {
        ...templateData.integrationData,
        id: getTemplateId,
        ...data,
      }
      await dispatch(setTemplateFormData(updatedTemplateData))
      if (data.template_id) {
        return data.template_id
      }
      await dispatch(
        updateAppAlert({
          message: DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export const setTemplateFormData = (integrationData: TemplateInCreation) => {
  return {
    type: TemplateCreationActionTypes.SET_CREATE_TEMPLATE_DATA,
    payload: integrationData,
  }
}
export const clearTemplateData = () =>
  ({
    type: TemplateCreationActionTypes.CLEAR_CURRENT_TEMPLATE_DATA,
  } as unknown as AnyAction)
