import { theme } from '../../../../theme'
import { BWAProduct, ListingType } from '../listingCardCreation/reducer'
import { ButtonCreationActionTypes } from './action'
import { Reducer } from 'redux'
import { v4 as uuid4 } from 'uuid'

export interface CardHeader {
  customization: {
    [key: string]: any
  }
  shouldShowLogoInCardHeader: boolean
  title?: string
}
export type cardOrientationType = 'BUTTON' | 'CARD' | ''
export enum CARD_CONTENT_TYPE {
  BUTTON = 'BUTTON',
  CARD = 'CARD',
}
export interface ButtonCustomization {
  backgroundColor?: string
  borderColor?: string
  color?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: string
  font?: string
  borderWidth?: string
  buttonShadow?: boolean
}

export interface ButtonType {
  customization: ButtonCustomization
  title: string
  key: string
  visibility?: boolean
  fieldType?: 'Outlined' | 'Filled'
}

export interface keysToDisplay {
  id?: string
  apiName: string
  displayName: string
  isRequired: boolean
  dataType: string
  validationType?: string | null
  minLength: number
  maxLength: number
  isExisting?: boolean
}
export interface FormType {
  title: string
  fieldsToDisplay: keysToDisplay[]
  link?: string
}
export interface CardType {
  customization: CardCustomization
}
export interface CardCustomization {
  font?: string
  // fontSize?: string
}
export interface IntegrationData {
  cards?: string[]
}

export interface ButtonsData {
  id?: string | null
  canvasColor?: string
  type?: ListingType
  integrationId?: string | null
  name?: string
  card?: CardType
  buttons?: ButtonType[]
  privateOfferForm?: FormType
  requestDemoForm?: FormType
  buyWithAwsLogoUrl?: string
  cardHeader?: CardHeader
  outlinedButton?: ButtonCustomization
  filledButton?: ButtonCustomization
  cardContentType?: cardOrientationType
  buttonCustomization?: ButtonCustomization
  shouldShowAwsLogo?: boolean
  selectedButton?: string
  product: BWAProduct[]
}
export interface ButtonInCreation {
  buttonData: ButtonsData
}

export const initialState: ButtonInCreation = {
  buttonData: {
    id: null,
    canvasColor: '#DFE4EF',
    name: '',
    card: { customization: { font: 'Poppins' } },
    buttons: [
      {
        customization: {},
        title: 'Buy with AWS',
        key: 'buy_with_aws',
        visibility: true,
        fieldType: 'Filled',
      },
    ],
    privateOfferForm: {
      title: 'Request a private offer',
      fieldsToDisplay: [],
      link: '',
    },
    requestDemoForm: {
      title: 'Request a demo',
      fieldsToDisplay: [],
      link: '',
    },
    buyWithAwsLogoUrl: 'https://assets.flyout.labra.io/misc/aws_available.png',
    cardHeader: {
      shouldShowLogoInCardHeader: false,
      customization: {
        color: '#000000',
        fontSize: '12px',
        fontWeight: 400,
      },
      title: '',
    },
    cardContentType: CARD_CONTENT_TYPE.BUTTON,
    outlinedButton: {
      fontSize: '20px',
      fontWeight: '400',
      color: theme.palette.button.primary,
      borderColor: theme.palette.textField.light,
    },
    filledButton: {
      fontSize: '20px',
      fontWeight: '400',
      color: theme.palette.button.primary,
      backgroundColor: theme.palette.button.secondary,
    },
    buttonCustomization: {
      fontSize: '20px',
      fontWeight: '400',
      color: theme.palette.button.primary,
      backgroundColor: theme.palette.button.secondary,
      borderColor: theme.palette.textField.light,
      font: 'Poppins',
      borderWidth: '1px',
      borderRadius: '4px',
    },
    shouldShowAwsLogo: true,
    selectedButton: 'buy_with_aws',
    type: ListingType.BUTTON,
    product: [
      {
        uniqueId: String(uuid4()),
        url: '',
        isOpen: true,
        isLoading: false,
      },
    ],
  },
}

type actionType =
  | {
      type: ButtonCreationActionTypes.SET_CREATE_BUTTON_DATA
      payload: {
        buttonData: ButtonInCreation
      }
    }
  | {
      type: ButtonCreationActionTypes.UPDATE_BUTTON_DATA
      payload: {
        integrationId: string
        buttonData: Record<string, string>
      }
    }
  | {
      type: ButtonCreationActionTypes.CLEAR_CURRENT_BUTTON_DATA
    }

export const reducer: Reducer<ButtonInCreation, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ButtonCreationActionTypes.SET_CREATE_BUTTON_DATA: {
      return {
        ...state,
        buttonData: {
          ...state.buttonData,
          ...action.payload,
        },
      }
    }
    case ButtonCreationActionTypes.CLEAR_CURRENT_BUTTON_DATA:
      return {
        ...initialState,
        buttonData: {
          ...initialState.buttonData,
          product: [
            {
              uniqueId: String(uuid4()),
              url: '',
              isOpen: true,
              isLoading: false,
            },
          ],
        },
      }

    default: {
      return state
    }
  }
}
