import React from 'react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@material-ui/core'
import { useStyles } from './DisbalePage.styles'
import { ButtonV2, ExternalLink } from '@labrav/react-components'
import padlock from '../../images/padlock.png'

export interface ProductInfoProps {
  title: string
  features: string[]
  subheading: string
  products?: string[]
  status?: string
  statusIndex?: number
  link?: string
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  title,
  features,
  subheading,
  products,
  status,
  statusIndex,
  link,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.contentContainer} data-testid="info">
      <Typography className={classes.title} data-testid="title">
        <img src={padlock} alt="lock" className={classes.lock} />
        {title}
      </Typography>
      {products && (
        <div className={classes.smallImagesContainer}>
          {products?.map((imageSrc, index) => (
            <div key={index} className={classes.imageWrapper}>
              <img
                src={imageSrc}
                alt={`Product ${index}`}
                className={classes.smallImage}
                data-testid={`product-image-${index}`}
              />
              {index === statusIndex && status && (
                <span className={classes.status}>{status}</span>
              )}
            </div>
          ))}
        </div>
      )}
      <Typography className={classes.subTitle} data-testid="subheading">
        {subheading}
      </Typography>
      {features.map((feature, index) => (
        <div className={classes.listItem} data-testid={`feature-${index}`}>
          <FontAwesomeIcon
            icon={faCheck}
            style={{ width: '14px', height: '15px', marginRight: '8px' }}
          />
          <li key={index} className={classes.listItemText}>
            {feature}
          </li>
        </div>
      ))}
      {link && (
        <div className={classes.link}>
          <ExternalLink href={link}>
            <ButtonV2 styleType="link">Visit website to learn more</ButtonV2>
          </ExternalLink>
        </div>
      )}
      <ButtonV2
        styleType="primary"
        className={classes.button}
        onClick={() =>
          window.open('https://helpcenter.labra.io/hc/en-us/requests/new')
        }
        data-testid="contact-button"
      >
        Contact Labra Team
      </ButtonV2>
    </div>
  )
}

export default ProductInfo
